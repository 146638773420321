<template>
    <div class="border mr-2 mt-0">
        <CRow>
            <CCol sm="12">
                <div class="table-responsive">
                    <table class="table table-borderless">
                        <thead>
                        <tr class="text-center">
                            <th scope="col" class="text-left">Account No</th>
                            <th scope="col" class="text-left">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">
                                Meter <br/>
                                Assigned?
                            </th>
                            <th
                                    scope="col"
                                    v-if="
                          checkUsrPermissions([
                            'CAcc_all',
                            'CAcc_show',
                            'CAcc_edit',
                            'CAccAssign_team',
                          ])
                        "
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody v-if="customer_accounts.length">
                        <tr
                                class="text-center"
                                v-for="customer in customer_accounts"
                                :key="customer.id"
                        >
                            <td scope="row" class="text-left">
                                <router-link :to="`/customer-accounts/${customer.id}`">{{
                                    customer.account_number
                                    }}
                                </router-link>
                            </td>
                            <td scope="row" class="text-left">
                                {{
                                customer.salutation +
                                " " +
                                customer.first_name +
                                " " +
                                customer.last_name
                                }}
                            </td>
                            <td scope="row">{{ customer.email }}</td>
                            <td scope="row">{{ customer.mobile_number }}</td>
                            <td scope="row">
                                {{ customer.hasMeter ? "Yes" : "No" }}
                            </td>
                            <td
                                    style="padding: 0px 10px;"
                                    scope="row"
                                    v-if="
                          checkUsrPermissions([
                            'CAcc_all',
                            'CAcc_show',
                            'CAcc_edit',
                            'CAccAssign_team',
                          ])
                        "
                            >
                                <CButton
                                    title="View Consumer Account"
                                    class="mb-1 mr-1"
                                    color="info"
                                    v-if="checkUsrPermissions(['CAcc_all', 'CAcc_show'])"
                                    @click="$router.push('/customer-accounts/' + customer.id)"
                                    shape="pill"
                                >  <i class="fa fa-eye"></i></CButton>
                                <CButton
                                        v-if="
                            !customer.hasMeter &&
                              customer.hasTask == false &&
                              checkUsrPermissions([
                                'CAcc_all',
                                'CAccAssign_team',
                              ])
                          "
                                        color="primary"
                                        shape="pill"
                                        @click="assignTeam(customer.id)"
                                >Assign Team
                                </CButton
                                >
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="9">
                                <h5 class="text-center">Data Not Available.</h5>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </CCol>
        </CRow>
        <CModal
                :closeOnBackdrop="false"
                v-if="assignTeamModal"
                title="Assign Team"
                color="primary"
                class="hide-footer custom-close-button"
                :show.sync="assignTeamModal"
        >
            <AssignTeam
                    v-if="assignTeamModal"
                    :customerId="customerId"
                    @closeAssignTeamModal="closeAssignTeamModal"
            ></AssignTeam>
        </CModal>
    </div>
</template>

<script>
import AssignTeam from "@/components/customer_accounts/AssignTeam.vue";

export default {
    name: "CreateCustomerAcc",
    components: {AssignTeam},
    props: ['customer_accounts'],
    data: () => {
        return {
            assignTeamModal: false,
            customerId: ""
        };
    },
    methods: {
        closeAssignTeamModal() {
            this.assignTeamModal = false;
        },
        assignTeam(customerId) {
            this.customerId = customerId;
            this.assignTeamModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
  button {
    margin: 10px;
    padding: 15px 20px;
  }
}
</style>
<style scoped>
.hide-footer >>> footer {
    display: none;
}
</style>
