<template>
  <CCard
    ><CRow>
      <CCol>
        <div v-if="serverErrors.length">
          <CAlert v-model="showDismissibleAlert" variant="danger" dismissible>
            <ul>
              <li v-for="err in serverErrors" :key="err">{{ err }}</li>
            </ul>
          </CAlert>
        </div>
        <form v-on:submit.prevent="assignMeter">
          <div class="form-group">
            <label>Meters</label>
            <select
              name="meter_id"
              v-model="meter_id"
              class="form-control"
              required
            >
              <option value="">Select Meter</option>
              <option v-for="meter in meters" :value="meter.id" :key="meter.id">
                {{ meter.meter_number }} - ({{ meter.hardware_serial_no }})
              </option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
export default {
  name: "AssignMeter",
  props: {
    customerId: Number,
  },
  data: () => {
    return {
      showToast:false,
      toastMessage:"",
      meter_id: "",
      meters: [],
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
    };
  },
  methods: {
    assignMeter() {
      this.isLoading = true;
      try {
        this.axios
          .post(`/admin/customers/${this.customerId}/assign-meter`, {
            meter_id: this.meter_id,
          })
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "Meter successfuly assigned to customer", "Meter Assigned");
            // this.$emit("closeAssignMeterModal");
            this.isLoading = false;
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }
            this.isLoading = false;
          });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    getMeters() {
      this.isLoading = true;
      this.axios
        .get(`/admin/meters/available`)
        .then((res) => {
          this.meters = res.data.data;
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getMeters();
  },
};
</script>

<style scoped lang="scss">
</style>
