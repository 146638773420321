var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CRow',[_c('CCol',[(_vm.serverErrors.length)?_c('div',_vm._l((_vm.serverErrors),function(err){return _c('CAlert',{key:err,attrs:{"color":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(err)+" ")])}),1):_vm._e(),_c('validation-observer',{ref:"formValidator"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.assignTeam.apply(null, arguments)}}},[_c('div',{staticClass:"row text-left"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Task Type","vid":"task.type","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Task Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.team.task.type),expression:"team.task.type"}],staticClass:"form-control",attrs:{"name":"type","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.team.task, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Task Type")]),_c('option',{attrs:{"value":"meter_installation"}},[_vm._v(" Meter Installation ")])])]),_c('p',{staticClass:"text-danger mt-2 pt-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Resolution time","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Resolution time (in hrs)")]),_c('CInput',{attrs:{"type":"number","addInputClasses":errors.length ? 'is-invalid' : '',"placeholder":"Enter Resolution Time","required":""},model:{value:(_vm.team.task.resolution_time),callback:function ($$v) {_vm.$set(_vm.team.task, "resolution_time", $$v)},expression:"team.task.resolution_time"}})],1),_c('p',{staticClass:"text-danger mt-0 pt-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Hardware Team","vid":"teamIds.0","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Hardware Team")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.hardware_team),expression:"hardware_team"}],staticClass:"form-control",attrs:{"name":"hardware_team","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.hardware_team=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Hardware Team")]),_vm._l((_vm.teams),function(team){return _c('option',{key:team.id,domProps:{"value":team.id}},[_vm._v(" "+_vm._s(team.name)+" "+_vm._s(team.type ? '(' + team.type + ')' : '')+" ")])})],2)]),_c('p',{staticClass:"text-danger mt-2 pt-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"QA Team","vid":"teamIds.1","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("QA Team")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.qa_team),expression:"qa_team"}],staticClass:"form-control",attrs:{"name":"qa_team","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.qa_team=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select QA Team")]),_vm._l((_vm.teams),function(team){return _c('option',{key:team.id,domProps:{"value":team.id}},[_vm._v(" "+_vm._s(team.name)+" "+_vm._s(team.type ? '(' + team.type + ')' : '')+" ")])})],2)]),_c('p',{staticClass:"text-danger mt-2 pt-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }