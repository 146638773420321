<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Consumers</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="9">
              <form v-on:submit.prevent="filterCustomer(filter)">
                <CRow>
                  <CCol sm="4" class="pr-0">
                    <select
                        name="projects"
                        v-model="filter.column"
                        class="form-control"
                    >
                      <option value="">Select Column</option>
                      <option
                          v-for="column in columns"
                          :key="column.id"
                          :value="column.id"
                      >
                        {{ column.name }}
                      </option>
                    </select>
                  </CCol>
                  <CCol sm="4" class="pr-0">
                    <CInput
                        v-model="filter.keyword"
                        placeholder="Search..."
                    />
                  </CCol>

                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>

            <CCol sm="3">
              <router-link :to="{name:'CreateCustomer'}" class="btn btn-primary float-right ml-2">
                <i class="fa fa-plus"></i> Add new
              </router-link>
              <router-link :to="{name:'CustomerImport'}" class="btn btn-dark float-right">
               <i class="fa fa-upload"></i> Import
              </router-link>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">SL</th>
                      <th scope="col">Account No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Status</th>
                      <th scope="col">Meter</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">
                        Ward <br />
                        Number
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="customers.length">
                  <template v-for="(customer, index) in customers">
                      <tr class="text-center" :key="customer.id" :class="{ active: isActive(index) }">
                        <td scope="row">{{ meta.from + index }}</td>
                        <td type="button" @click="toggleDetails(index, customer.id)" scope="row">
                          <i style="font-size:18px" class="fa" :class="[isActive(index) ? 'fa-chevron-up' : 'fa-chevron-down' ]"></i>
                          {{ customer.account_number }}
                        </td>
                        <td scope="row">
                          {{
                            customer.salutation +
                            " " +
                            customer.first_name +
                            " " +
                            customer.last_name
                          }}
                          </td>
                        <td scope="row">{{ customer.category }}</td>
                        <td scope="row">{{ customer.status }}</td>
                        <td scope="row">{{ customer.total_meter }}</td>
                        <td scope="row">{{ customer.mobile_number }}</td>
                        <td scope="row">{{ customer.ward_number }}</td>
                        <td scope="row">
                              <CButton
                                      title="View Consumer"
                                      class="mb-1 mr-1"
                                      color="info"
                                      @click="$router.push('/customer/' + customer.id)"
                                      shape="pill"
                              >  <i class="fa fa-eye"></i></CButton
                              >
                              <CButton
                                      title="Create Consumer Account"
                                      color="primary"
                                      shape="pill"
                                      @click="createCustomerModal = true; selected_customer_id=customer.id"
                              >
                                  <i class="fa fa-plus"></i>
                              </CButton>
                          </td>
                      </tr>
                      <tr :key="customer.id + ' child'" v-if="isActive(index) && customerAccounts.length">
                          <td class="border" colspan="10">
                              <CustomerAccountsView :customer_accounts="customerAccounts"/>
                          </td>
                      </tr>
                  </template>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="customers.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>

      <CModal
              :closeOnBackdrop="false"
              size="lg"
              :title="selected_customer_id ? 'Add Consumer account' : 'Add Consumer'"
              color="primary"
              class="hide-footer custom-close-button"
              :show.sync="createCustomerModal"
      >
          <AddCustomer
                  :selected_customer_id="selected_customer_id"
                  :areas="areaLists"
                  :micodeLists="micodeLists"
                  v-if="createCustomerModal"
                  @closeAddCustomerModal="closeAddCustomerModal"
          ></AddCustomer>
      </CModal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddCustomer from "./CreateCustomer";
import CustomerAccountsView from "./CustomerAccountsView";
import AssignMeter from "./AssignMeter";
export default {
  name: "Customer",
  components: {
    AddCustomer,
    AssignMeter,
    CustomerAccountsView
  },
  data() {
    return {
      activeIndex: null,
      createCustomerModal: false,
      customerId: "",
      customer_id: "",
      selected_customer_id: "",
      columns: [
        {id: 'area', name: 'AREA CODE'},
        {id: 'account_number', name: 'ACCOUNT'},
        {id: 'first_name', name: 'NAME'},
        {id: 'guardian', name: 'FATHER NAME'},
        {id: 'address', name: 'ADDR1'},
        {id: 'ward_number', name: 'WARD NO'},
        {id: 'mobile_number', name: 'CONTACT NO'},
        {id: 'status', name: 'METER STATUS'},
        {id: 'category', name: 'CATEGORY'},
        {id: 'meter_number', name: 'METER NO'},
        // {id: 'ri_code', name: 'RI CODE'},
        {id: 'batch_no', name: 'BATCH NO'},
        {id: 'mi_code', name: 'MI CODE'},
      ],
      filter: {
        column: "",
        keyword: "",
        currentPage: 1,
      },
    };
  },
  methods: {
    toggleDetails(index, customer_id) {
        if (this.activeIndex === index) {
            this.activeIndex = null;
        } else {
            this.$store.dispatch("CustomerAccount/getCustomerAccounts", {customer_id});
            this.activeIndex = index;
        }
    },
    isActive(index) {
        return this.activeIndex === index;
    },
    assignMeter(customerId) {
      this.customerId = customerId;
      this.$router.push({
        name: "Assign Meter",
        params: { id: customerId },
      });
    },
    closeAddCustomerModal() {
      this.createCustomerModal = false;
      this.selected_customer_id = ""
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$store.dispatch("Customer/getCustomers", this.filter);
    },
    filterCustomer(search) {
      if (search) {
        search.currentPage = 1;
        this.$store.dispatch("Customer/getCustomers", search);
      }
    },
  },
  computed: {
    ...mapGetters("Customer", ["customers", "rows", "perPage", "meta"]),
    ...mapGetters("CustomerAccount", ["customerAccounts"]),
    ...mapGetters("Areas", ["areaLists"]),
    ...mapGetters("MiCode", ["micodeLists"]),
  },
  mounted() {
    this.filter.account_number = this.$route.query.account_number;
    this.filter.mobile_number = this.$route.query.mobile_number;
    this.$store.dispatch("Customer/getCustomers", this.filter);
    this.$store.dispatch("Areas/getAreaLists");
    this.$store.dispatch("MiCode/getMiCodeLists");
  },
};
</script>

<style scoped>
.name-column{
    min-width: 220px;
}
tr.active{
    color: #0c63e4 !important;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(2) {
          color: #5e76e7;
        }
        td:nth-child(7) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
