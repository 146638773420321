<template>
  <CCard
    ><CRow>
      <CCol>
        <div v-if="serverErrors.length">
          <CAlert
            v-for="err in serverErrors"
            :key="err"
            v-model="showDismissibleAlert"
            color="danger"
            dismissible
          >
            {{ err }}
          </CAlert>
        </div>
        <validation-observer ref="formValidator">
          <form v-on:submit.prevent="assignTeam">
            <div class="row text-left">
              <div class="col-md-6">
                <ValidationProvider
                  name="Task Type"
                  vid="task.type"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label>Task Type</label>
                    <select
                      name="type"
                      v-model="team.task.type"
                      class="form-control"
                      required
                    >
                      <option value="">Task Type</option>
                      <option value="meter_installation">
                        Meter Installation
                      </option>
                    </select>
                  </div>

                  <p class="text-danger mt-2 pt-0">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="Resolution time"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label>Resolution time (in hrs)</label>
                    <CInput
                      type="number"
                      :addInputClasses="errors.length ? 'is-invalid' : ''"
                      v-model="team.task.resolution_time"
                      placeholder="Enter Resolution Time"
                      required
                    />
                  </div>
                  <p class="text-danger mt-0 pt-0">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="Hardware Team"
                  vid="teamIds.0"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label>Hardware Team</label>
                    <select
                      name="hardware_team"
                      v-model="hardware_team"
                      class="form-control"
                      required
                    >
                      <option value="">Select Hardware Team</option>
                      <option
                        v-for="team in teams"
                        :value="team.id"
                        :key="team.id"
                      >
                        {{ team.name }} {{ team.type ? '(' + team.type + ')' : '' }}
                      </option>
                    </select>
                  </div>

                  <p class="text-danger mt-2 pt-0">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="QA Team"
                  vid="teamIds.1"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label>QA Team</label>
                    <select
                      name="qa_team"
                      required
                      v-model="qa_team"
                      class="form-control"
                    >
                      <option value="">Select QA Team</option>
                      <option
                        v-for="team in teams"
                        :value="team.id"
                        :key="team.id"
                      >
                        {{ team.name }} {{ team.type ? '(' + team.type + ')' : '' }}
                      </option>
                    </select>
                  </div>

                  <p class="text-danger mt-2 pt-0">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </validation-observer>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AssignMeter",
  props: {
    customerId: Number,
  },
  data: () => {
    return {
      hardware_team: "",
      qa_team: "",
      team: {
        teamIds: [],
        task: {
          type: "meter_installation",
          resolution_time: "",
        },
      },
      meter_id: "",
      meters: [],
      serverErrors: [],
      showDismissibleAlert: false,
      perPage: 100,
    };
  },
  methods: {
    assignTeam() {
      this.isLoading = true;
      this.team.teamIds = [];
      this.team.teamIds.push({team_id: this.hardware_team, type: 'hardware'}, {team_id:this.qa_team, type: 'qa'});

      try {
        this.$store.dispatch("Settings/loading", true);
        this.$store
          .dispatch("CustomerAccount/assignTeamToCustomer", {
            team: this.team,
            customerId: this.customerId,
          })
          .then(
            () => {
              this.$store.dispatch("Settings/loading", false);
              this.$toastr.s(
                "Teams successfuly assigned to customer",
                "Team Assigned"
              );
              this.$emit("closeAssignTeamModal");
            },
            (err) => {
              this.$store.dispatch("Settings/loading", false);
              this.showDismissibleAlert = true;
              this.serverErrors = [];
              if (err.response.data) {
                this.serverErrors.push(err.response.data.message);
                this.$refs.formValidator.setErrors(err.response.data.errors);
              }
            }
          );
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("Teams", ["teams"]),
    // qaTeams() {
    //   const result = this.teams.filter((team) => team.type == "qa");
    //   return result;
    // },
    // hardwareTeams() {
    //   const result = this.teams.filter((team) => team.type == "hardware");
    //   return result;
    // },
  },
  mounted() {
    this.$store.dispatch("Teams/getTeamList", this.perPage);
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
  padding: 0px !important;
}
</style>
