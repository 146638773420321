var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border mr-2 mt-0"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless"},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Account No")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Email")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Mobile No")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Meter "),_c('br'),_vm._v(" Assigned? ")]),(
                      _vm.checkUsrPermissions([
                        'CAcc_all',
                        'CAcc_show',
                        'CAcc_edit',
                        'CAccAssign_team' ])
                    )?_c('th',{attrs:{"scope":"col"}},[_vm._v(" Actions ")]):_vm._e()])]),(_vm.customer_accounts.length)?_c('tbody',_vm._l((_vm.customer_accounts),function(customer){return _c('tr',{key:customer.id,staticClass:"text-center"},[_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_c('router-link',{attrs:{"to":("/customer-accounts/" + (customer.id))}},[_vm._v(_vm._s(customer.account_number)+" ")])],1),_c('td',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(customer.salutation + " " + customer.first_name + " " + customer.last_name)+" ")]),_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(customer.email))]),_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(customer.mobile_number))]),_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(customer.hasMeter ? "Yes" : "No")+" ")]),(
                      _vm.checkUsrPermissions([
                        'CAcc_all',
                        'CAcc_show',
                        'CAcc_edit',
                        'CAccAssign_team' ])
                    )?_c('td',{staticStyle:{"padding":"0px 10px"},attrs:{"scope":"row"}},[(_vm.checkUsrPermissions(['CAcc_all', 'CAcc_show']))?_c('CButton',{staticClass:"mb-1 mr-1",attrs:{"title":"View Consumer Account","color":"info","shape":"pill"},on:{"click":function($event){return _vm.$router.push('/customer-accounts/' + customer.id)}}},[_c('i',{staticClass:"fa fa-eye"})]):_vm._e(),(
                        !customer.hasMeter &&
                          customer.hasTask == false &&
                          _vm.checkUsrPermissions([
                            'CAcc_all',
                            'CAccAssign_team' ])
                      )?_c('CButton',{attrs:{"color":"primary","shape":"pill"},on:{"click":function($event){return _vm.assignTeam(customer.id)}}},[_vm._v("Assign Team ")]):_vm._e()],1):_vm._e()])}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"9"}},[_c('h5',{staticClass:"text-center"},[_vm._v("Data Not Available.")])])])])])])])],1),(_vm.assignTeamModal)?_c('CModal',{staticClass:"hide-footer custom-close-button",attrs:{"closeOnBackdrop":false,"title":"Assign Team","color":"primary","show":_vm.assignTeamModal},on:{"update:show":function($event){_vm.assignTeamModal=$event}}},[(_vm.assignTeamModal)?_c('AssignTeam',{attrs:{"customerId":_vm.customerId},on:{"closeAssignTeamModal":_vm.closeAssignTeamModal}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }